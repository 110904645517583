//@flow
import React, { memo, type Node } from 'react';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { palette } from '@dt/theme';

import Label from './Label';

type Props = {|
  +icon?: Node,
  +label: string,
  +rounded?: boolean,
  +noPadding?: boolean,
  +tooltipText?: string,
  +backgroundColor?: string,
  +color?: string,
  +maxWidth?: ?number | ?string,
  +verticalAdjustment?: ?number,
|};

const useStyles = makeStyles(() => ({
  container: ({
    rounded,
    backgroundColor,
    color,
    maxWidth,
    verticalAdjustment,
  }) => ({
    width: 'fit-content',
    padding: '2px 8px',
    marginTop: verticalAdjustment || 'initial',
    borderRadius: rounded ? 16 : 4,
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: 14,
    backgroundColor:
      typeof backgroundColor === 'string' ? backgroundColor : palette.gray45,
    color: typeof color === 'string' ? color : palette.gray20,
    height: '1.8em',
    maxWidth: maxWidth,
  }),
  label: ({ icon }) => ({
    marginLeft: icon ? 4 : 0,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 250,
  }),
  icon: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function Tag({
  icon,
  label,
  backgroundColor,
  color,
  tooltipText,
  rounded = false,
  maxWidth,
  verticalAdjustment,
  noPadding,
}: $Shape<Props>) {
  const classes = useStyles({
    rounded,
    icon,
    backgroundColor,
    color,
    maxWidth,
    verticalAdjustment,
  });

  const tooltipTitle = typeof tooltipText === 'string' ? tooltipText : label;

  return (
    <Tooltip title={tooltipTitle} aria-label={tooltipTitle}>
      {/* Span is used for Tooltip to register content area. */}
      <span style={{ display: 'inline-flex', whiteSpace: 'nowrap' }}>
        <Label rounded={rounded} variant="transparent" noPadding={noPadding}>
          <div className={classes.container}>
            <div className={classes.icon}>
              {icon ? icon : <span style={{ height: 14 }} />}
            </div>
            <span className={classes.label}>{label}</span>
          </div>
        </Label>
      </span>
    </Tooltip>
  );
}

export default memo<$Shape<Props>>(Tag);
